import Smaphon2Col, { MainWidth } from "templates/Smaphon2Col";
import { styled } from "@mui/material/styles";
import mainImage from "assets/img/01_NMRtoha.png";
import iconZimu from "assets/img/02_NMRtokutyou_01.png";
import iconCustomize from "assets/img/02_NMRtokutyou_02.png";
import iconTrack from "assets/img/02_NMRtokutyou_03.png";
import iconMoniter from "assets/img/02_NMRtokutyou_04.png";
import imgWhole from "assets/img/04_NMRzentai.png";
import { Shine } from "styles/button/shine";

// Rest of the code

const Parent01 = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(2),
  color: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexFlow: "column",
  gap: theme.spacing(2),
  "& hr": {
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
}));

const Parent02 = styled(Parent01)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  "& hr": {
    borderColor: theme.palette.primary.main,
  },
  "& h3": {
    color: theme.palette.primary.main,
  },
  "& .caption": {
    ...theme.typography.caption,
    overflowWrap: "break-word",
  },
}));

const Headline = styled("h2")(({ theme }) => ({
  ...theme.typography.h2,
  maxWidth: "80%",
  fontSize: "1.5rem",
}));

export default function Main() {
  return (
    <Smaphon2Col>
      <Kv />
      <About />
      <Caption />
      <Features />
      <Fee />
      <DownloadButton />
    </Smaphon2Col>
  );
}

function Kv() {
  const DivKv = styled("div")(({ theme }) => ({
    position: "relative",
    maxWidth: "380px",
    margin: "auto",
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(4),
    "& img": {
      width: "100%",
      display: "block",
    },
  }));

  const Headline = styled("h1")(({ theme }) => ({
    maxWidth: "80%",
    fontSize: "1.5rem",
    objectFit: "contain",
    textAlign: "center",
    border: "unset",
    margin: "auto",
    "& .logo-img": {},
  }));

  const Catchcopy = styled("p")(({ theme }) => ({
    maxWidth: "150px",
    fontSize: "1.5rem",
    fontWeight: "100",
    textAlign: "center",
    margin: "auto",
    letterSpacing: "0.3em",
    fontFamily: "M PLUS Rounded 1c, sans-serif",
    color: "rgb(112, 112, 112)",
    "& span": {
      textAlign: "left",
      display: "block",
      "&::first-letter": {
        fontSize: "150%", // 文字のサイズを変更
        color: "red", // 文字の色を変更
        fontWeight: "bold",
      },
    },
  }));

  return (
    <DivKv id="kv">
      <img src={mainImage} alt="イメージマップ" />
      <Headline>
        <span>運送業のNEXTステージへ</span>
      </Headline>
      <Catchcopy>
        <span>Next</span>
        <span>Movement</span>
        <span>Reacher</span>
      </Catchcopy>
    </DivKv>
  );
}

/**
 * システム定義
 * @returns
 */
function About() {
  const Item = styled("div")(({ theme }) => ({
    placeItems: "center",
    letterSpacing: "0.3em",
    width: "100%",
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(1),
    "& h3": theme.typography.h3,
    "& .label": theme.typography.subtitle1,
    "& .name span:nth-of-type(1):after": {
      content: "'/'",
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    "& .caption":
      (theme.typography.caption, { "& span": { display: "block" } }),
  }));

  return (
    <Parent01 id="about">
      <Headline>NMRシステムとは？</Headline>
      <hr />
      <Item>
        <p className="caption">
          <span>NMRとは、運送業向けの完全な業務支援システムです。</span>
          <span>
            お客様からの問い合わせから始まり、輸送計画や必要帳票の発行など事務作業を効率的に行います。さらに輸送情報に手当を登録することで給与明細の発行や、車両管理機能による点検や車検管理も容易に行えます。
          </span>
        </p>
      </Item>
    </Parent01>
  );
}

/**
 * システムの説明
 * @returns
 */
function Caption() {
  const Item = styled("div")(({ theme }) => ({
    placeItems: "center",
    letterSpacing: "0.3em",
    width: "100%",
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(1),
    "& h3": theme.typography.h3,
    "& .label": theme.typography.subtitle1,
    "& .name span:nth-of-type(1):after": {
      content: "'/'",
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    "& .caption":
      (theme.typography.caption, { "& span": { display: "block" } }),
  }));

  return (
    <Parent02 id="caption">
      <Headline>完全サポートで、運送業の未来を明るく</Headline>
      <hr />
      <Item>
        <p className="caption">
          <span>我々のNMRシステムは、運送業界に革新をもたらします。</span>
          <span>
            運送業務から事務作業までをシームレスに行え、車両管理機能を含め、全てを一元管理できます。
          </span>
          <span>未来への投資として、</span>
          <span>当システムをお試しください。</span>
        </p>
      </Item>
      <img id="caption-img" src={imgWhole} alt="全体イメージ" />
    </Parent02>
  );
}

/**
 *
 * @returns 特徴
 */
function Features() {
  const Items = styled("div")(({ theme }) => ({
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    "& .item": {
      display: "flex",
      flexFlow: "rows",
      gap: theme.spacing(4),
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(4),
      "& img": {
        width: "100%",
        maxWidth: "100px",
      },
      "& .caption": {
        ...theme.typography.caption,
        color: theme.palette.primary.main,
        fontWeight: "900",
        "& span": {
          display: "block",
        },
      },
    },
  }));

  return (
    <Parent02 id="features">
      <Headline>運送業で必要な事務作業を一括管理！</Headline>
      <hr />
      <Items>
        <div className="item">
          <img src={iconZimu} alt="事務作業のアイコン" />
          <p className="caption">
            <span>見積情報から請求書の発行まで！</span>
            <span>さらに手当てを登録して給与計算まで行います！</span>
          </p>
        </div>
        <div className="item">
          <img src={iconCustomize} alt="カスタマイズのアイコン" />
          <p className="caption">
            <span>専用サーバにインストールも可能！</span>
            <span>
              他社に影響されず、自由にカスタマイズしたいという要望にもお応えします。
            </span>
          </p>
        </div>
        <div className="item">
          <img src={iconTrack} alt="トラックのアイコン" />
          <p className="caption">
            <span>車両管理機能も完備！</span>
            <span>次回の車検が近い車両のお知らせもします。</span>
          </p>
        </div>
        <div className="item">
          <img src={iconMoniter} alt="モニターのアイコン" />
          <p className="caption">
            <span>インターネットがあれば</span>
            <span>どこでも使える！</span>
          </p>
        </div>
      </Items>
    </Parent02>
  );
}

function Fee() {
  // サブタイトル
  const SubTitle = styled("h3")(({ theme }) => ({
    ...theme.typography.h3,
    color: theme.palette.primary.main,
    textAlign: "center",
    fontWeight: "900",
    fontSize: "1.2rem",
  }));

  const Table = styled("table")(({ theme }) => ({
    width: "100%",
    tableLayout: "fixed",
    textAlign: "center",
    borderCollapse: "collapse",
    border: `1px solid ${theme.palette.primary.main}`,
    "& *": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    "& thead": {
      backgroundColor: "rgba(237,119,0,0.9)",
      color: theme.palette.primary.contrastText,
    },
    "& .caption": {
      ...theme.typography.caption,
      display: "flex",
      justifyContent: "space-between",
    },
  }));

  const Item = styled("div")(({ theme }) => ({
    placeItems: "center",
    letterSpacing: "0.3em",
    width: "100%",
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(1),
    "& h3": theme.typography.h3,
    "& .label": theme.typography.subtitle1,
    "& .name span:nth-of-type(1):after": {
      content: "'/'",
      marginLeft: "1rem",
      marginRight: "1rem",
    },
    "& .caption":
      (theme.typography.caption, { "& span": { display: "block" } }),
  }));

  return (
    <Parent02 id="fee">
      <Headline>料金</Headline>
      <hr />

      <SubTitle>サービス型ソフトウェア</SubTitle>
      <Table>
        <thead>
          <tr>
            <th>初期費用</th>
            <th>月額</th>
            <th>1年目の料金</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>¥300,000-</td>
            <td>¥100,000-</td>
            <td>¥1,500,000-</td>
          </tr>
        </tbody>
      </Table>

      <SubTitle>専用サーバ</SubTitle>
      <Table>
        <thead>
          <tr>
            <th>初期費用</th>
            <th>月額</th>
            <th>1年目の料金</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>¥500,000-</td>
            <td>¥200,000-</td>
            <td>¥2,900,000-</td>
          </tr>
        </tbody>
      </Table>

      <Item className="item">
        <p className="caption">
          <span>
            お客様のニーズに合わせ、オンプレミス環境への対応やカスタマイズにも柔軟に対応いたします。
          </span>
          <span>お気軽にお問い合わせください。</span>
        </p>
      </Item>
    </Parent02>
  );
}

function DownloadButton() {
  const CaptionA = styled("a")(({ theme }) => ({
    fontSize: "2.0rem",
    fontWeight: "900",
    textAlign: "center",
    color: theme.palette.primary.contrastText,
    letterSpacing: "0.5rem",
    textDecoration: "underline",
  }));

  // 新しいスタイルを定義
  const styleOverwrap = {
    maxWidth: "300px",
    margin: "auto",
  };

  // 新しいスタイルを追加したShineコンポーネント
  const ShineButton = styled(Shine)(styleOverwrap);

  return (
    <ShineButton id="download">
      <CaptionA href="/NMRシステム申請書類_g09.pdf" download>
        資料はこちら
      </CaptionA>
    </ShineButton>
  );
}
