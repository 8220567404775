import { useEffect, useState } from 'react';
import { theme } from "theme/Smaphon2Col";

// デバイスの種類を定義するenum
export enum DeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

// デバイス判定関数
const getDeviceType = (width: number): DeviceType => {
  if (width <= theme.breakpoints.values.sm) {
    return DeviceType.Mobile;
  } else if (width <= theme.breakpoints.values.lg) {
    return DeviceType.Tablet;
  } else {
    return DeviceType.Desktop;
  }
};

const useDeviceType = (): DeviceType => {
  const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      const newDeviceType = getDeviceType(window.innerWidth);
      if (newDeviceType !== deviceType) {
        setDeviceType(newDeviceType);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [deviceType]);

  return deviceType;
};

export default useDeviceType;
