import { styled } from "@mui/material/styles";
import { theme } from "theme/Smaphon2Col";
import { TriangleAnimation, LeftIcon } from "components/Link";
import iconMailWhiteImage from "assets/img/icon_mail_white.svg";

export default function Main() {
  const Aside = styled("aside")(({ theme }) => ({
    width: "100%",
    maxWidth: "350px",
    maxHeight: "60vh",
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(5),
    padding: theme.spacing(3),
    position: "sticky",
    top: theme.spacing(11),
    "& *":{
      fontSize: theme.spacing(3),
      color: theme.palette.primary.main,
    } 

  }));

  const AnkerLinkes = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexFlow: "column",
    gap: theme.spacing(4),
    "& *": {  
      backgroundColor: theme.palette.secondary.main,
    }
  }));

  const IconLinkes = styled(AnkerLinkes)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxSizing: "border-box",
  }));

  const LineStyle = {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  };

  const MailStyle = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    "& *": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
  };

  return (
    <Aside id="side">
      <AnkerLinkes>
        <TriangleAnimation link="#about" label="NMRシステムとは？" />
        <TriangleAnimation link="#caption-img" label="全体図" />
        <TriangleAnimation link="#fee" label="料金" />
        <TriangleAnimation link="#download" label="資料請求" />
        <LeftIcon
          link="mailto:nmr@addonem.com"
          icon={iconMailWhiteImage}
          label="メール"
          linkStyle={MailStyle}
        />
      </AnkerLinkes>
    </Aside>
  );
}
