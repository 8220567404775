import { Paper } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { useTheme } from "@mui/material/styles";

interface CustomScrollbarProps {
  width: string;
  height: string;
  children: ReactNode;
}

const CustomScrollbar = ({
  width = "100%",
  height = "100%",
  children,
}: CustomScrollbarProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const theme = useTheme();
  return (
    <Paper
      sx={{
        "&::-webkit-scrollbar": {
          width: "3px",
          //display: isHovered ? "block" : "none", // マウスが要素内で動いているときに表示
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
        overflow: "auto",
        width: width,
        height: height,
        border: "none",
        boxShadow: "none",
        [theme.breakpoints.down("sm")]: {
          borderRadius: "0",
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* ここにコンテンツを追加 */}
      {children}
    </Paper>
  );
};

export default CustomScrollbar;
