import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import mainLogoImage from "assets/addonem/logofull.svg";

export default function Main() {
  const Logo = styled("img")(({ theme }) => ({
    margin: "auto",
    width: "auto",
    height: "60px",
    transform: "translate(0%, 0%)",
    backgroundColor: "unset",
    filter:
      "invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%)",
  }));

  return (
    <AppBar component="header">
      <a href="https://addonem.com?route=nmr.addonem.com">
        <Logo src={mainLogoImage} alt="" />
      </a>
    </AppBar>
  );
}
