import { styled } from "@mui/material/styles";

export function Address() {
  const Address = styled("div")(({ theme }) => ({
    ...theme.typography.caption,
    display: "flex",
    gap: theme.spacing(1),
    flexFlow: "column",
    fontSize: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    "& p": {
      margin: "unset",
      "& span": {
        display: "block",
      },
    },
    "& p.post-num:before": {
      content: '"〒"',
    },
  }));

  const Link = styled("a")(({ theme }) => ({
    ...theme.typography.caption,
    fontSize: "2.0rem",
    textDecoration: "none",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& *": {
      margin: "unset",
      fontWeight: "700",
    },
  }));

  return (
    <Address className="address">
      <Link href="https://addonem.com?route=nmr.addonem.com">
        https://addonem.com
      </Link>
      <Link href="nmr@addonem.com">nmr@addonem.com</Link>
    </Address>
  );
}
